<template>
  <arora-button
    v-if="appConfig.VueSettingsPreRun.FeedbackShowButton"
    :class-name="useFooterColor ? 'v-btn v-btn-border v-btn-footer-color' : ' '"
    :ignore-settings="useFooterColor"
    :label="translate('feedbackList.topButton')"
    @click="async () => await showFeedbackPopup()"
  >
    <slot>
      <span v-html="translate('feedbackList.topButton')" />
    </slot>
  </arora-button>
</template>

<script setup lang="ts">
defineProps<{
  useFooterColor?: boolean
}>()
const popupStore = usePopupStore()

const appConfig = useAppConfig()
const { translate } = useI18nSanitized()

async function showFeedbackPopup(): Promise<void> {
  await popupStore.openPopup({
    popupClosable: true,
    popupName: 'feedbackPopup'
  })
}
</script>
